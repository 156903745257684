/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        
        // JavaScript to be fired on all pages
        $('.trigger-nav').on( 'click' , function(){
          $(this).toggleClass('on');
          $('.main-nav').toggleClass('on');
        });

        
        $('.tlt').textillate({
          // the default selector to use when detecting multiple texts to animate
          selector: '.texts',

          // enable looping
          loop: true,

          // sets the minimum display time for each text before it is replaced
          minDisplayTime: 10000,

          // sets the initial delay before starting the animation
          // (note that depending on the in effect you may need to manually apply 
          // visibility: hidden to the element before running this plugin)
          initialDelay: 0,

          // set whether or not to automatically start animating
          autoStart: true,

          // custom set of 'in' effects. This effects whether or not the 
          // character is shown/hidden before or after an animation  
          inEffects: [],

          // custom set of 'out' effects
          //outEffects: [ 'fadeInLeft' ],

          // in animation settings
          in: {
            // set the effect name
            effect: 'fadeInLeft',

            // set the delay factor applied to each consecutive character
            delayScale: 1.3,

            // set the delay between each character
            delay: 20,

            // set to true to animate all the characters at the same time
            sync: false,

            // randomize the character sequence 
            // (note that shuffle doesn't make sense with sync = true)
            shuffle: false,

            // reverse the character sequence 
            // (note that reverse doesn't make sense with sync = true)
            reverse: false,

            // callback that executes once the animation has finished
            callback: function () {}
          },

          // out animation settings.
          out: {
            effect: 'fadeOutRight',
            delayScale: 1,
            delay: 15,
            sync: false,
            shuffle: false,
            reverse: false,
            callback: function () {}
          },

          // callback that executes once textillate has finished 
          callback: function () {},

          // set the type of token to animate (available types: 'char' and 'word')
          type: 'char'
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
